#about{
    /* width: ; */
    background-color: rgba(255,255,255,0.5) ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 50%;
    border-radius: 10px;
}

#description{
    width: 80%;
    padding-bottom: 15px;
}

@media only screen and (max-width: 600px) {
    #about{
        width: 90%;
        margin-top:2vh;
    }
  }