.card{
    margin: 10px;
    /* flex-grow: 1; */
    width: 90%;
    flex-basis: auto;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    margin:0;
    padding-bottom: 10px;
}
.project-card{
    background-color: rgba(255,255,255,0.8);
    width: 30%;
    margin: 2vh;
    border-radius: 5px;
}
.item-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    margin:0;
    padding-bottom: 10px;
}

.project-title{
    display: flex;
    justify-content: center;
    font-style: bold;
    margin-top: 0;
    padding-bottom: 1vh;
}

.project-image{
    width: 100%;
    padding-top: 2vh;
    padding-bottom: 2vh;
    max-height: 30vh;
}

.project-type, .project-date, .project-tools{
    margin: 0;
    background-color: rgba(255,255,255,0) !important;
    padding-top: 1vh;
    padding: 0.5vh;
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
}

#projects{
    background-color: #EFC88B;
    padding-top: 10px;
}

.project-date{
    padding-bottom: 1.5vh;
}

.tags-container, .tools-container{
    display: flex;
    flex-direction: row;
}

.project-type1{
    background-color: rgba(204,113,120,.5) !important ;
    margin-top:1vh;
    /* margin-bottom: 2px; */
    margin-right: 0.5vh;
    align-items: center;
}

.project-type2{
    background-color: rgba(20,62,48,.5) !important ;
    margin-top:1vh;
    align-items: center;
    /* margin-bottom: 2px; */
}

.project-tools{
    background-color: rgba(239,200,139,.5) !important ;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-right: 0.5vh;
    align-items: center;
}

.git{
    width: 4vh;
    height: 4vh;
}

@media only screen and (max-width: 600px) {
    .item-container{
        flex-direction: column;
    }

    .project-card{
        width: 90%;
    }
  }