#copyright{
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.icon{
    width: 7vh;
    height: 7vh;
}

#icons{
    padding-top: 10px;
    padding-bottom: 10px;
}